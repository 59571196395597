import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight, Zap, TrendingUp, AlignLeft } from 'lucide-react';
import { useAuth } from '../context/AuthContext';


const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleGetStarted = () => {
    if (user) {
      navigate('/subscription');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="landing-page">
      <header>
        <div className="logo">
          
          <span>Tweet Vortex</span>
        </div>
        <nav>
          <ul>
          <li><a href="#features" onClick={(e) => { e.preventDefault(); document.getElementById('features').scrollIntoView({ behavior: 'smooth' }); }}>Features</a></li>
          <li><a href="#pricing" onClick={(e) => { e.preventDefault(); document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' }); }}>Pricing</a></li>
          </ul>
        </nav>
      </header>

      <section className="hero">
        <h1>Create Viral Tweets Using AI</h1>
        <p>Generate
 engaging tweets and threads using up-to-the-minute news and articles published within the last 24 hours
</p>
        <button className="cta-button" onClick={handleGetStarted}>
          Get Started
          <ArrowRight className="w-5 h-5 ml-2" />
        </button>
      </section>

      <section className="video-section">
        <div className="video-container">
          <video 
            controls 
            autoPlay
            muted
            poster="/placeholder.svg?height=450&width=800"
            className="demo-video"
          >
            <source src="demo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      <section id="features" className="features">
        <h2>Powerful AI Features</h2>
        <div className="feature-grid">
          <div className="feature-card">
            <div className="feature-icon-wrapper">
              <div className="gradient-icon">
                <Zap className="w-6 h-6" />
              </div>
            </div>
            <h3>Uses Latest Info from Internet</h3>
            <p>Our AI Uses Latest Info from Internet to create up-to-date tweets, be with the trends! . Stay ahead with fresh, relevant content at your fingertips.</p>
          </div>

          <div className="feature-card">
            <div className="feature-icon-wrapper">
              <div className="gradient-icon">
                <TrendingUp className="w-6 h-6" />
              </div>
            </div>
            <h3>Viral Tweet Algorithm</h3>
            <p>Our AI uses grok Api which is best for tweet generation as its trained on a lot of tweet data. Generate content engineered to maximize engagement and ride Twitter's algorithm.</p>
          </div>

          <div className="feature-card">
            <div className="feature-icon-wrapper">
              <div className="gradient-icon">
                <AlignLeft className="w-6 h-6" />
              </div>
            </div>
            <h3>Entire Threads</h3>
            <p>Create compelling tweet threads that keep your audience hooked. Break down complex topics into engaging narratives that establish you as a thought leader.</p>
          </div>
        </div>
      </section>

      <section className="tweet-generation">
  <div className="tweet-gen-container">
    <h2>Viral Tweets from Real-Time News</h2>
    <div className="tweet-gen-content">
      <div className="tweet-gen-info">
        <div className="real-time-badge">Real-Time Generation</div>
        <h3>Tweets are generated from real-time articles published within the last 24 hours</h3>
        <p>Tweets are generated from real-time articles published within the last 24 hours and uses Elon Musk's Latest Grok 2 Model's Api to create Tweets best for twitter algorithm to organically grow.</p>
        <div className="tweet-gen-buttons">
          <button className="tweet-gen-primary" onClick={handleGetStarted}>
            Try Tweet Generator
            <ArrowRight className="w-5 h-5 ml-2" />
          </button>
         
        </div>
      
      </div>
      <div className="tweet-preview">
        <div className="tweet-header">
          <div className="tweet-number">Tweet #1</div>
          <div className="viral-score">
            <div className="score-circle">
              <svg className="score-ring">
                <circle className="score-bg" r="20" cx="24" cy="24" />
                <circle className="score-progress" r="20" cx="24" cy="24" />
              </svg>
              <span className="score-text">72%</span>
            </div>
            <span className="score-label">Viral Score</span>
          </div>
        </div>
        <div className="tweet-content">
          <p className="tweet-title">OpenAI just announced o3, o3 Mini AI models, release in 2025!</p>
          <p className="tweet-text">As the 'Shipmass,' by OpenAI comes to an end, it announced its latest launch. The AI company said on December 20, that it was testing new reasoning AI models named o3 and o3 mini. Here's everything you need to know:</p>
          <div className="tweet-hashtags">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

      <section id="pricing" className="pricing">
        <h2>Choose Your Plan</h2>
        <div className="pricing-grid">
          <div className="pricing-card basic">
            <h3>Basic</h3>
            <p className="price">$19<span>/month</span></p>
            <ul>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                20 AI-generated tweets per day
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Realtime Updates
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                optimized for twitter algorithm
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Basic Support
              </li>
            </ul>
            <button className="pricing-cta" onClick={handleGetStarted}>
              Get Started
            </button>
          </div>
          <div className="pricing-card pro">
            <div className="popular-tag">Popular</div>
            <h3>Lifetime</h3>
            <p className="price">$299<span>/Lifetime</span></p>
            <ul>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Lifetime access
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                30 AI-generated tweets per day
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Best for tweeter Algorithm
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Priority Support
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Priority content curation
              </li>
            </ul>
            <button className="pricing-cta" onClick={handleGetStarted}>
              Get Started
            </button>
          </div>
        </div>
      </section>

      <footer>
        <p>&copy; 2023 Tweet Vortex. All rights reserved.</p>
        <nav>
          <ul>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
          </ul>
        </nav>
      </footer>

      <style jsx global="true">{`
        
        html {
          scroll-behavior: smooth;
          background: #000000; /* Solid black background as fallback */
          min-height: 100%;
        }

        body {
          background: linear-gradient(to bottom, #000000, #111827);
          margin: 0;
          padding: 0;
          min-height: 100vh;
          color: #ffffff;
          overflow-x: hidden;
        }

        /* Ensure the landing page container also has a dark background */
        .landing-page {
          background: linear-gradient(to bottom, #000000, #111827);
          color: #ffffff;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          scroll-behavior: smooth;
          overflow-x: hidden;
          position: relative;
          overflow-y: auto;
          min-height: 100vh;
        }

        header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem 2rem;
          max-width: 1400px;
          margin: 0 auto;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 100;
        }

        .logo {
          display: flex;
          align-items: center;
          gap: 0.75rem;
        }

        .twitter-icon {
          width: 2rem;
          height: 2rem;
          fill: #60a5fa;
        }

        .logo span {
          font-size: 1.25rem;
          font-weight: 600;
          background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0.8));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        nav ul {
          display: flex;
          gap: 1.5rem;
          list-style-type: none;
        }

        nav a {
          color: #ffffff;
          text-decoration: none;
          transition: color 0.3s;
        }

        nav a:hover {
          color: #60a5fa;
        }

        .hero {
          text-align: center;
          padding: 8rem 1rem;
          max-width: 1200px;
          margin: 0 auto;
        }

        h1 {
          font-size: 5rem;
          font-weight: bold;
          margin-bottom: 2rem;
          line-height: 1.1;
          background: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0.7));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .hero p {
          font-size: 1.5rem;
          color: #9ca3af;
          margin-bottom: 3rem;
          max-width: 800px;
          margin-left: auto;
          margin-right: auto;
          line-height: 1.5;
        }

        .cta-button {
          background: #ffffff;
          color: #000000;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.75rem 2rem;
          border: none;
          border-radius: 0.5rem;
          cursor: pointer;
          transition: all 0.3s ease;
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
          width:200px;
        }

        .cta-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
        }

        .video-section {
          padding: 2rem 1rem;
          max-width: 800px;
          margin: 0 auto;
        }

        .video-container {
          aspect-ratio: 16 / 9;
          background-color: #1f2937;
          border-radius: 0.5rem;
          overflow: hidden;
        }

        .demo-video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .features {
          padding: 4rem 2rem;
          background: #0a0b0c;
        }

        .features h2 {
          font-size: 2.5rem;
          font-weight: 600;
          text-align: center;
          margin-bottom: 3rem;
          background: linear-gradient(to right, #3b82f6, #8b5cf6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .feature-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          max-width: 1200px;
          margin: 0 auto;
        }

        .feature-card {
          background: rgba(26, 27, 30, 0.5);
          border-radius: 1rem;
          padding: 2rem;
          transition: transform 0.3s ease;
          border: 1px solid rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
        }

        .feature-card:hover {
          transform: translateY(-5px);
        }

        .feature-icon-wrapper {
          margin-bottom: 1.5rem;
        }

        .gradient-icon {
          width: 3rem;
          height: 3rem;
          border-radius: 0.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(45deg, #3b82f6, #8b5cf6);
          color: white;
        }

        .feature-card h3 {
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1rem;
          color: #fff;
        }

        .feature-card p {
          color: #9ca3af;
          line-height: 1.6;
          font-size: 0.95rem;
        }

        .pricing {
          background-color: #1f2937;
          padding: 5rem 1rem;
        }

        .pricing h2 {
          font-size: 2.25rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 3rem;
        }

        .pricing-grid {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          gap: 2rem;
          max-width: 1000px;
          margin: 0 auto;
        }

        .pricing-card {
          background-color: #111827;
          padding: 2rem;
          border-radius: 0.5rem;
          text-align: center;
          position: relative;
        }

        .pricing-card.pro {
          border: 1px solid #3b82f6;
        }

        .popular-tag {
          position: absolute;
          top: 0;
          right: 0;
          background-color: #3b82f6;
          color: #ffffff;
          font-size: 0.875rem;
          font-weight: 600;
          padding: 0.25rem 1rem;
          border-top-right-radius: 0.5rem;
        }

        .pricing-card h3 {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .price {
          font-size: 2.25rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }

        .price span {
          font-size: 1.25rem;
          font-weight: normal;
        }

        .pricing-card ul {
          list-style-type: none;
          margin-bottom: 2rem;
        }

        .pricing-card li {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.5rem;
        }

        .pricing-card li svg {
          width: 1.25rem;
          height: 1.25rem;
          stroke: #34d399;
        }

        .pricing-cta {
          background-color: #3b82f6;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.5rem 2rem;
          border: none;
          border-radius: 9999px;
          cursor: pointer;
          transition: background-color 0.3s;
          width: 100%;
        }

        .pricing-cta:hover {
          background-color: #2563eb;
        }

        footer {
          background-color: #1f2937;
          color: #ffffff;
          padding: 2rem;
          text-align: center;
          border-top: 1px solid #374151;
        }

        footer p {
          margin: 0;
          font-size: 0.875rem;
          opacity: 0.8;
        }

        footer nav {
          margin-top: 1rem;
        }

        footer ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
          gap: 2rem;
        }

        footer li {
          margin: 0;
        }

        footer a {
          color: #3b82f6;
          text-decoration: none;
          font-weight: 500;
          transition: color 0.3s, transform 0.3s;
        }

        footer a:hover {
          color: #60a5fa;
          transform: translateY(-2px);
        }

        .tweet-generation {
          background: linear-gradient(to bottom, #0a0b0c, #111827);
          position: relative;
          overflow: hidden;
          padding: 64px 0 96px;
        }

        .tweet-generation::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: radial-gradient(circle at 50% 50%, rgba(59, 130, 246, 0.1), transparent 70%);
          pointer-events: none;
        }

        .tweet-gen-container {
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 16px;
        }

        .tweet-generation h2 {
          font-size: 48px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 48px;
          background: linear-gradient(to right, #60a5fa, #8b5cf6);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .tweet-gen-content {
          display: flex;
          align-items: center;
          gap: 48px;
          max-width: 1120px;
          margin: 0 auto;
        }

        .tweet-gen-info {
          flex: 1;
          space-y: 24px;
        }

        .real-time-badge {
          display: inline-block;
          background: rgba(59, 130, 246, 0.1);
          color: #60a5fa;
          padding: 4px 12px;
          border-radius: 8px;
          font-size: 14px;
          margin-bottom: 16px;
        }

        .tweet-gen-info h3 {
          font-size: 36px;
          font-weight: bold;
          color: #ffffff;
          margin-bottom: 24px;
        }

        .tweet-gen-info p {
          color: #9ca3af;
          font-size: 18px;
          line-height: 1.6;
          margin-bottom: 32px;
        }

        .tweet-gen-buttons {
          display: flex;
          gap: 16px;
        }

        .tweet-gen-primary {
          display: inline-flex;
          align-items: center;
          padding: 12px 24px;
          background: #2563eb;
          color: white;
          border-radius: 8px;
          font-weight: 500;
          transition: background-color 0.3s;
        }

        .tweet-gen-primary:hover {
          background: #1d4ed8;
        }

        .tweet-gen-secondary {
          display: inline-flex;
          align-items: center;
          padding: 12px 24px;
          background: transparent;
          color: #9ca3af;
          border: 1px solid #374151;
          border-radius: 8px;
          font-weight: 500;
          transition: border-color 0.3s;
        }

        .tweet-gen-secondary:hover {
          border-color: #4b5563;
        }

        .tweet-preview {
          flex: 1;
          max-width: 512px;
          background: rgba(17, 24, 39, 0.5);
          border: 1px solid #374151;
          border-radius: 12px;
          backdrop-filter: blur(8px);
          padding: 24px;
        }

        .tweet-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
        }

        .tweet-number {
          color: #60a5fa;
          font-weight: 600;
        }

        .viral-score {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .score-circle {
          position: relative;
          width: 48px;
          height: 48px;
        }

        .score-ring {
          width: 48px;
          height: 48px;
          transform: rotate(90deg);
        }

        .score-bg {
          fill: none;
          stroke: #374151;
          stroke-width: 4;
        }

        .score-progress {
          fill: none;
          stroke: #60a5fa;
          stroke-width: 4;
          stroke-linecap: round;
          stroke-dasharray: 90, 125;
        }

        .score-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          font-weight: bold;
          color: white;
        }

        .score-label {
          color: #9ca3af;
          font-size: 14px;
        }

        .tweet-content {
          color: white;
        }

        .tweet-title {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 16px;
        }

        .tweet-text {
          color: #d1d5db;
          line-height: 1.6;
          margin-bottom: 16px;
        }

        .tweet-hashtags {
          display: flex;
          gap: 8px;
          color: #60a5fa;
          font-size: 14px;
        }

        @media (max-width: 1024px) {
          .tweet-gen-content {
            flex-direction: column;
          }

          .tweet-preview {
            width: 100%;
          }
        }

        @media (max-width: 768px) {
          .tweet-generation h2 {
            font-size: 36px;
          }

          .tweet-gen-info h3 {
            font-size: 28px;
          }

          .tweet-gen-buttons {
            flex-direction: column;
          }

          .tweet-gen-primary,
          .tweet-gen-secondary {
            width: 100%;
            justify-content: center;
          }
        }

        @media (max-width: 768px) {
          header {
            padding: 1rem;
          }
          .hero h1 {
            font-size: 3rem;
          }
          .hero p {
            font-size: 1.25rem;
          }
          nav ul {
            justify-content: center;
          }

          .feature-grid, .pricing-grid {
            grid-template-columns: 1fr;
          }

          .features {
            padding: 3rem 1rem;
          }
          
          
          .features h2 {
            font-size: 2rem;
          }
        }
          html {
          scroll-behavior: smooth;
        }
      `}</style>
    </div>
  );
};

export default Landing; 