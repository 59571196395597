import React from 'react';
import '../styles/TermsOfService.css'; // Import the CSS

const TermsOfService = () => {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>Welcome to Tweet Vortex! By using our services, you agree to the following terms and conditions.</p>
      <h2>Use of Our Services</h2>
      <p>You must use our services in compliance with all applicable laws and regulations. You are responsible for your account and its security.</p>
      <h2>Intellectual Property</h2>
      <p>All content provided by Tweet Vortex is owned by us or our licensors. You may not use our content without permission.</p>
      <h2>Limitation of Liability</h2>
      <p>We are not liable for any indirect, incidental, or consequential damages arising from your use of our services.</p>
      <h2>Changes to These Terms</h2>
      <p>We may modify these terms at any time. Your continued use of our services after changes indicates your acceptance of the new terms.</p>

      
    </div>
  );
};

export default TermsOfService; 