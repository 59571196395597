import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>At Tweet Vortex, we value your privacy. This policy outlines how we collect, use, and protect your information.</p>
      <h2>Information We Collect</h2>
      <p>We collect information that you provide directly to us, such as when you create an account, subscribe to our services, or contact us.</p>
      <h2>How We Use Your Information</h2>
      <p>Your information is used to provide and improve our services, communicate with you, and comply with legal obligations.</p>
      <h2>Data Security</h2>
      <p>We implement appropriate security measures to protect your personal information from unauthorized access, use, or disclosure.</p>
      <h2>Changes to This Policy</h2>
      <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on our website.</p>

      
    </div>
  );
};

export default PrivacyPolicy;