import { auth } from '../config/firebase';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/.netlify/functions';

const checkAuthToken = async () => {
  const currentUser = auth.currentUser;
  
  if (!currentUser) {
    throw new Error('Please log in to generate tweets');
  }

  try {
    const token = await currentUser.getIdToken(true);
    return token;
  } catch (error) {
    console.error('Token refresh error:', error);
    throw new Error('Please log in to generate tweets');
  }
};

export const generateTweets = async (formData) => {
  try {
    const token = await checkAuthToken();

    const response = await fetch(`${API_BASE_URL}/generate-tweets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(formData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to generate tweets');
    }

    const data = await response.json();
    return data.tweets;
  } catch (error) {
    if (error.message === 'Failed to fetch') {
      throw new Error('Unable to connect to server. Please check if the backend server is running.');
    }
    throw error;
  }
};