import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { db } from '../config/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      setLoading(true);

      if (user) {
        // Create subscription listener
        const subscriptionsRef = collection(db, 'subscriptions');
        const q = query(
          subscriptionsRef,
          where('userId', '==', user.uid),
          where('status', '==', 'active')
        );

        const unsubscribeSnapshot = onSnapshot(q, (snapshot) => {
          let activeSubscription = null;
          
          snapshot.forEach((doc) => {
            const data = doc.data();
            if (data.planId === 'Lifetime Plan') {
              activeSubscription = { ...data, id: doc.id };
            } else {
              const expiryDate = data.expiresAt?.toDate();
              if (expiryDate && expiryDate > new Date()) {
                activeSubscription = { ...data, id: doc.id };
              }
            }
          });
          
          setSubscription(activeSubscription);
          setLoading(false);
        });

        return () => unsubscribeSnapshot();
      } else {
        setSubscription(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    loading,
    subscription
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};