import React from 'react';
import { motion } from 'framer-motion';


function TweetDisplay({ tweets, contentType }) {
  const isThread = contentType === 'thread';

  const getScoreColor = (score) => {
    if (score >= 80) return '#4ade80';
    if (score >= 60) return '#fbbf24';
    return '#ef4444';
  };

  const formatTweet = (tweet) => {
    
    const cleanTweet = tweet.replace(/^Tweet \d+(?:\/\d+)?:\s*/i, '')
      .replace(/\*\*/g, '')
      .trim();

    // Split into paragraphs and filter empty lines
    const paragraphs = cleanTweet.split('\n')
      .filter(line => line.trim())
      .map(line => line.trim());

    return paragraphs.map((paragraph, idx) => (
      <p
        key={idx}
        className="tweet-paragraph"
        dangerouslySetInnerHTML={{
          __html: paragraph.replace(/(#\w+|@\w+)/g, '<span style="color: #1d9bf0">$1</span>')
        }} />
    ));
  };

  const handleShare = (tweetContent) => {
    const text = tweetContent.replace(/^Tweet \d+(?:\/\d+)?:\s*/i, '').replace(/\*\*/g, '').trim();
    const encodedText = encodeURIComponent(text);
    window.open(`https://twitter.com/intent/tweet?text=${encodedText}`, '_blank');
  };

  const threadScore = isThread ?
    Math.round(tweets.reduce((acc, t) => acc + t.score, 0) / tweets.length) : null;

  return (
    <div className="tweet-display">
      <h2 className="tweet-display-title">
        {isThread ? 'Generated Thread' : 'Generated Tweets'}
      </h2>

      {isThread && threadScore && (
        <div className="thread-score-container">
          <div className="score-circle" style={{
            background: `conic-gradient(${getScoreColor(threadScore)} ${threadScore}%, #1f2937 ${threadScore}%)`
          }}>
            <div className="score-inner">
              <span>{threadScore}%</span>
            </div>
          </div>
          <span className="thread-score-label">Thread Viral Score</span>
        </div>
      )}

      <div className={`tweets-grid ${isThread ? 'tweets-grid-thread' :
          contentType === 'long' ? 'tweets-grid-long' : 'tweets-grid-short'}`}>
        {tweets.map((tweetData, index) => (
          <React.Fragment key={index}>
            <motion.div
              className={`tweet-card-detailed ${isThread ? 'thread-card' : ''}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <div className="tweet-header">
                <div className="tweet-number">
                  {isThread ? `Tweet ${index + 1}/${tweets.length}` : `Tweet #${index + 1}`}
                </div>
                <div className="viral-score">
                  <div className="score-circle" style={{
                    background: `conic-gradient(${getScoreColor(tweetData.score)} ${tweetData.score}%, #1f2937 ${tweetData.score}%)`
                  }}>
                    <div className="score-inner">
                      <span>{tweetData.score}%</span>
                    </div>
                  </div>
                  <span className="score-label">Viral Score</span>
                </div>
              </div>
              <div className="tweet-content">
                {formatTweet(tweetData.tweet)}
              </div>
              <div className="tweet-actions">
                <button
                  onClick={() => handleShare(tweetData.tweet)}
                  className="tweet-share-btn"
                  title="Share on Twitter"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                  Share
                </button>
              </div>
            </motion.div>
            {isThread && index < tweets.length - 1 && (
              <div className="thread-connector">
                <div className="connector-dot" />
                <div className="connector-line" />
                <div className="connector-dot" />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default TweetDisplay;