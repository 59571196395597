import React from 'react';
import ContentTypeSelector from '../ContentTypeSelector';

const TweetForm = ({ onSubmit, loading }) => {
  const [formData, setFormData] = React.useState({
    category: '',
    contentType: 'long',
    tweetContext: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting with content type:', formData.contentType);
    onSubmit(formData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log('Input change:', name, value);
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="input-form">
      <div className="form-group">
        <label htmlFor="category">Category or Topic</label>
        <input
          type="text"
          id="category"
          name="category"
          value={formData.category}
          onChange={handleInputChange}
          placeholder="e.g., Technology, AI, Startups"
          required
        />
      </div>

      <ContentTypeSelector 
        selected={formData.contentType}
        onChange={handleInputChange}
      />

      <div className="form-group">
        <label htmlFor="tweetContext">Additional Context (Optional)</label>
        <textarea
          id="tweetContext"
          name="tweetContext"
          value={formData.tweetContext}
          onChange={handleInputChange}
          placeholder="Add any specific style instructions or context"
        />
      </div>

      <button type="submit" disabled={loading} className="submit-button">
        {loading ? 'Generating...' : 'Generate Tweets'}
      </button>
    </form>
  );
};

export default TweetForm; 