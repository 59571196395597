import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useAuth } from '../context/AuthContext';
import { db } from '../config/firebase';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  serverTimestamp, 
  Timestamp, 
  doc, 
  updateDoc, 
  deleteDoc 
} from 'firebase/firestore';
import '../styles/Subscription.css'

const PLANS = {
  basic: {
    price: '19.00',
    name: 'Basic Plan',
    duration: '1 month',
    features: [
      '20 AI-generated tweets per day',
      'Realtime Updates',
      'Optimized for twitter algorithm',
      'Basic Support'
    ]
  },
  lifetime: {
    price: '299.00',
    name: 'Lifetime Plan',
    duration: 'Lifetime',
    features: [
      'Lifetime access',
      '30 AI-generated tweets per day',
      'Best for twitter Algorithm',
      'Priority Support'
    ]
  }
};

const Subscription = () => {
  const navigate = useNavigate();
  const { user, subscription } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (subscription) {
      const isActive = subscription.status === 'active';
      const isLifetime = subscription.planId === 'Lifetime Plan';
      const expiryDate = subscription.expiresAt?.toDate();
      const isValid = isLifetime || (expiryDate && expiryDate > new Date());

      if (isActive && isValid) {
        navigate('/generate');
      }
    }
  }, [subscription, navigate]);

  const createSubscription = async (paymentDetails, plan) => {
    const now = new Date();
    await addDoc(collection(db, 'subscriptions'), {
      userId: user.uid,
      planId: plan.name,
      orderId: paymentDetails.id,
      status: 'active',
      createdAt: serverTimestamp(),
      amount: plan.price,
      expiresAt: plan.name === 'Lifetime Plan' ? null : 
        Timestamp.fromDate(new Date(now.setMonth(now.getMonth() + 1))),
      paymentDetails: {
        paypalOrderId: paymentDetails.id,
        paymentStatus: paymentDetails.status
      }
    });
  };

  const PayPalButton = ({ plan, onSuccess }) => {
    const [{ isResolved }] = usePayPalScriptReducer();
    const [error, setError] = useState(null);
    const { user, subscription } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (subscription) {
        const isActive = subscription.status === 'active';
        const isLifetime = subscription.planId === 'Lifetime Plan';
        const expiryDate = subscription.expiresAt?.toDate();
        const isValid = isLifetime || (expiryDate && expiryDate > new Date());

        if (isActive && isValid) {
          navigate('/generate');
        }
      }
    }, [subscription, navigate]);

    const createOrder = async (data, actions) => {
      try {
        if (!user) {
          throw new Error('Please login first');
        }

        // Check current subscription status from Firestore directly
        const subscriptionsRef = collection(db, 'subscriptions');
        const q = query(
          subscriptionsRef,
          where('userId', '==', user.uid),
          where('status', '==', 'active')
        );
        
        const querySnapshot = await getDocs(q);
        let hasActiveSubscription = false;
        
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.planId === 'Lifetime Plan') {
            hasActiveSubscription = true;
          } else if (data.expiresAt) {
            const expiryDate = data.expiresAt.toDate();
            if (expiryDate > new Date()) {
              hasActiveSubscription = true;
            }
          }
        });

        if (hasActiveSubscription) {
          throw new Error('You already have an active subscription');
        }

        return actions.order.create({
          purchase_units: [{
            amount: {
              value: plan.price,
              currency_code: 'USD'
            },
            description: `${plan.name} Subscription`
          }]
        });
      } catch (err) {
        console.error('Order creation error:', err);
        setError(err.message || 'Failed to create order. Please try again.');
        throw err;
      }
    };

    if (!user) {
      return <div className="error-message">Please login to make a purchase</div>;
    }

    return (
      <>
        {error && <div className="error-message">{error}</div>}
        {isResolved && !subscription && (
          <PayPalButtons
            style={{ 
              layout: "vertical",
              shape: 'rect'
            }}
            createOrder={createOrder}
            onApprove={async (data, actions) => {
              try {
                const details = await actions.order.capture();
                await createSubscription(details, plan);
                onSuccess(details);
              } catch (error) {
                console.error('Payment Error:', error);
                setError('Payment failed. Please try again.');
              }
            }}
            onError={(err) => {
              console.error('PayPal Error:', err);
              setError('Payment failed. Please try again.');
            }}
            onCancel={() => {
              setError('Payment cancelled. Please try again if you wish to subscribe.');
            }}
          />
        )}
      </>
    );
  };

  const handleSuccess = async (order) => {
    try {
      setLoading(true);
      navigate('/generate');
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to process subscription. Please contact support.');
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="subscription-container">
        <h2>Please login to view subscription plans</h2>
      </div>
    );
  }

  if (subscription && subscription.status === 'active') {
    return (
      <div className="subscription-container">
        <h2>Active Subscription</h2>
        <div className="subscription-info">
          <h3>{subscription.planId}</h3>
          {subscription.planId !== 'Lifetime Plan' && subscription.expiresAt && (
            <p>Valid until: {subscription.expiresAt.toDate().toLocaleDateString()}</p>
          )}
          {subscription.planId === 'Lifetime Plan' && (
            <p>Lifetime access</p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="subscription-container">
      {error && <div className="error-message">{error}</div>}
      {loading && <div className="loading-spinner">Processing payment...</div>}
      <div className="pricing-plans">
        {/* Basic Plan Card */}
        <div className="plan-card">
          <h3>{PLANS.basic.name}</h3>
          <p className="price">${PLANS.basic.price}<span>/month</span></p>
          <ul>
            {PLANS.basic.features.map((feature, index) => (
              <li key={index}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                {feature}
              </li>
            ))}
          </ul>
          <PayPalButton 
            plan={PLANS.basic} 
            onSuccess={(order) => handleSuccess(order)}
          />
        </div>

        {/* Lifetime Plan Card */}
        <div className="plan-card popular">
          <div className="popular-tag">Popular</div>
          <h3>{PLANS.lifetime.name}</h3>
          <p className="price">${PLANS.lifetime.price}<span>/lifetime</span></p>
          <ul>
            {PLANS.lifetime.features.map((feature, index) => (
              <li key={index}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                {feature}
              </li>
            ))}
          </ul>
          <PayPalButton 
            plan={PLANS.lifetime} 
            onSuccess={(order) => handleSuccess(order)}
          />
        </div>
      </div>
    </div>
  );
};

export default Subscription; 