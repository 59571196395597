import React, { useState, useRef, useEffect } from 'react';
import TweetForm from './TweetForm';
import TweetDisplay from './TweetDisplay';
import { generateTweets } from '../../services/api';
import '../../styles/App.css';

const TweetGenerator = () => {
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState(null);
  const tweetDisplayRef = useRef(null);

  const handleGenerateTweets = async (data) => {
    setLoading(true);
    setError(null);
    setFormData(data);

    try {
      console.log('Generating tweets with:', data);
      const generatedTweets = await generateTweets(data);
      
      if (!generatedTweets || !generatedTweets.length) {
        throw new Error('No tweets were generated');
      }
      
      setTweets(generatedTweets);
    } catch (error) {
      console.error('Error generating tweets:', error);
      setError(error.message || 'Failed to generate tweets');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tweets.length > 0 && tweetDisplayRef.current) {
      const yOffset = -20; // Adjust this value as needed
      const y = tweetDisplayRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [tweets]); // This effect runs whenever tweets change

  return (
    <div className="tweet-generator">
      <header className="App-header">
        <h1>TweetVortex</h1>
        <p className="subtitle">AI-Powered Tweet Generator</p>
      </header>
      
      <main className="content">
        <TweetForm onSubmit={handleGenerateTweets} loading={loading} />
        
        {error && <div className="error-message">{error}</div>}
        
        {tweets.length > 0 && (
          <div ref={tweetDisplayRef}>
            <TweetDisplay tweets={tweets} contentType={formData?.contentType} />
          </div>
        )}
      </main>
    </div>
  );
};

export default TweetGenerator; 