import React from 'react';
import '../styles/ContactUs.css'; 

const ContactUs = () => {
  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <p>If you have any questions or concerns, feel free to reach out to us!</p>
      <h2>Email</h2>
      <p>You can contact us at <a href="mailto:vivekkumardhigarla24@gmail.com">vivekkumardhigarla24@gmail.com</a></p>
      <h2>Social Media</h2>
      <p>Follow us on our social media channels for updates:</p>
      <ul>
        <li><a href="https://twitter.com/vivek_3000" target="_blank" rel="noopener noreferrer">Twitter</a></li>
        
      </ul>

      
    </div>
  );
};

export default ContactUs; 