import React from 'react';

const ContentTypeSelector = ({ selected, onChange }) => {
  const options = [
    {
      id: 'short',
      title: 'Short Tweets',
      description: 'Generate concise, impactful tweets',
      icon: '⚡'
    },
    {
      id: 'long',
      title: 'Detailed Tweets',
      description: 'Create comprehensive, analytical tweets',
      icon: '📝'
    },
    {
      id: 'thread',
      title: 'Tweet Thread',
      description: 'Build engaging tweet threads',
      icon: '🧵'
    }
  ];

  return (
    <div className="content-type-selector">
      <label className="selector-label">Content Type</label>
      <div className="options-grid">
        {options.map(option => (
          <div
            key={option.id}
            className={`option-card ${selected === option.id ? 'selected' : ''}`}
            onClick={() => onChange({ target: { name: 'contentType', value: option.id } })}
          >
            <span className="option-icon">{option.icon}</span>
            <h3>{option.title}</h3>
            <p>{option.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentTypeSelector; 